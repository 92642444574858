
.preprint-page {
    .top-banner-wrap {
        width: 100%;
        background: #cb2f3c;
        min-height: 300px;
        .top-banner-component {
            display: flex;
            justify-content: space-between;
            .left-top-banner {
                width: 380px;
                height: 250px;
                margin-top: 25px;
                padding: 18px;
                box-sizing: border-box;
                position: relative;
                z-index: 0;
                h5 {
                    font-size: 22px;
                    line-height: 22px;
                    color: #fff;
                    margin-bottom: 12px;
                }
                div {
                    z-index: 10;
                    .banner-content {
                        font-size: 14px;
                        line-height: 26px;
                        color: #fff;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                    }
                    span {
                        font-size: 16px;
                        color: #fcbb0b;
                        float: right;
                        margin-top: 4px;
                        cursor: pointer;
                    }
                }
                .left-top-banner-bg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 380px;
                    height: 250px;
                    background-image: url('../assets/img/bannerbg.png');
                    background-size: 100% 100%;
                    opacity: 0.4;
                    z-index: -1;
                }
            }
        }
    }
    .preprint-main {
        .news {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            .news-wrap {
                width: 588px;
                border: 1px solid #f5f5f5;
                box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
                padding: 10px 20px 3px;
                box-sizing: border-box;
                .news-content {
                    font-size: 18px;
                    line-height: 18px;
                    margin-top: 20px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 25px;
                        p {
                            font-size: 18px;
                            line-height: 18px;
                            cursor: pointer;
                            position: relative;
                            padding-left: 10px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 420px;
                            a {
                                color: #333;
                                font-size: 18px;
                                line-height: 18px;
                                display: block;
                                overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            }
                        }
                        p::before {
                            content: '';
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #ffac13;
                            position: absolute;
                            left: 0;
                            top: 8px;
                        }
                        span {
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }
            .left-news-wrap {
            }
            .right-news-wrap {
            }
        }
    }
    .article-wrap {
        padding: 10px 30px 0;
        margin: 26px auto;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .book-wrap {
        padding: 10px 30px 0;
        margin-bottom: 26px;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
}
